import React, { FC } from 'react';
import styled from 'styled-components';

import Section from 'src/components/Section';
import { Testimonial } from 'src/components/Testimonials';
import { mediaQuery } from 'src/theme/breakpoints';

const Grid = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 80px;
  grid-template-columns: 1fr;
  padding-top: 60px;

  ${mediaQuery('sm')} {
    grid-row-gap: 100px;
    grid-template-columns: repeat(2, 1fr);
  }

  ${mediaQuery('md')} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

interface TestimonialsGridProps {
  testimonials: any;
}

const TestimonialsGrid: FC<TestimonialsGridProps> = ({ testimonials }) => (
  <Section title="Rekomendacijos">
    <Grid>
      {testimonials &&
        testimonials.map(({ person, image, review }, index) => (
          <Testimonial
            image={image}
            name={person}
            text={review}
            key={`${person}-${index}`}
          />
        ))}
    </Grid>
  </Section>
);

export default TestimonialsGrid;
