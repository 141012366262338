import React, { FC } from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { colors } from 'src/theme/colors';
import { mediaQuery } from 'src/theme/breakpoints';
import { QuoteIcon } from 'src/assets/icons';

const Wrapper = styled.div`
  align-items: center;
  background-color: ${colors.white};
  border-radius: 25px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  display: flex;
  flex-direction: column;
  padding: 0 10px 20px;
  position: relative;
  width: 100%;

  ${mediaQuery('lg')} {
    padding: 0 30px 20px;
  }
`;

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Name = styled.span`
  color: ${colors.blue500};
  font-size: 16px;
  font-weight: 700;
`;

const Text = styled.p`
  font-style: italic;
  text-align: center;
`;

const Photo = styled(GatsbyImage)`
  border-radius: 50%;
  height: 100px;
  margin-top: -50px;
  width: 100px;
`;

const StyledIcon = styled(QuoteIcon)`
  height: 20px;
  left: 20px;
  position: absolute;
  top: 20px;
  transform: rotate(180deg);
  width: 20px;

  & path {
    fill: ${colors.blue500};
  }
`;

interface TestimonialProps {
  image: any;
  name: string;
  text: string;
}

const Testimonial: FC<TestimonialProps> = ({ image, name, text }) => (
  <Wrapper>
    <StyledIcon />
    {image && <Photo alt={image.altText} image={getImage(image.localFile)} />}
    <Content>
      <Text>{text}</Text>
      <Name>{name}</Name>
    </Content>
  </Wrapper>
);

export default Testimonial;
