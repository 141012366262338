import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import Gallery from 'src/components/Gallery';
import { mediaQuery } from 'src/theme/breakpoints';
import { colors } from 'src/theme/colors';

const Tabs = styled.ul`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr;
  list-style: none;
  padding: 0;
  width: 100%;

  ${mediaQuery('md')} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const Tab = styled.li<Pick<ProductTabProps, 'selected'>>`
  background-color: ${colors.blue300};
  border-radius: 10px;
  height: 40px;
  transition: background-color 0.3s;

  &:not(:first-child) {
    margin-top: 20px;
  }

  ${mediaQuery('md')} {
    border-radius: 0;

    &:first-child {
      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;
    }

    &:last-child {
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
    }

    &:not(:last-child) {
      border-right: 1px solid ${colors.white};
    }

    &:not(:first-child) {
      margin-top: 0;
    }
  }

  &:hover {
    background-color: ${colors.orange500};
  }

  ${({ selected }) =>
    selected &&
    css`
      &:nth-child(${selected}) {
        background-color: ${colors.orange500};
      }
    `}
`;

const TabButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${colors.white};
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  height: 100%;
  text-transform: uppercase;
  width: 100%;

  &:focus:not(:focus-visible) {
    outline: 0;
  }
`;

interface ProductTabProps {
  tabs: {
    name: string;
    content: string;
  }[];
  galleryImages: any;
  selected?: number;
}

const ProductTabs: React.FC<ProductTabProps> = ({ tabs, galleryImages }) => {
  const tabsContent = tabs.map(tab => tab.content);
  const galleryIndex = Object.keys(tabs).length + 1;
  const [currentTab, setTab] = useState({
    content: tabsContent[0],
    index: 0,
  });

  const setCurrentTab = index => {
    if (index === currentTab.index) return;

    setTab({ content: tabsContent[index], index });
  };

  return (
    <>
      <Tabs>
        {tabs.map((tab, index) => (
          <Tab
            selected={currentTab.index + 1}
            key={tab.name}
            onClick={() => setCurrentTab(index)}
          >
            <TabButton>{tab.name}</TabButton>
          </Tab>
        ))}
        {galleryImages && (
          <Tab
            selected={currentTab.index}
            key="Galerija"
            onClick={() => setCurrentTab(galleryIndex)}
          >
            <TabButton>Galerija</TabButton>
          </Tab>
        )}
      </Tabs>
      <div dangerouslySetInnerHTML={{ __html: currentTab.content }} />
      {currentTab.index === galleryIndex && <Gallery images={galleryImages} />}
    </>
  );
};

export default ProductTabs;
