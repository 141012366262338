import React, { FC } from 'react';
import styled from 'styled-components';

import { ProductTabs, ProductSummary } from 'src/components/Product';
import Section from 'src/components/Section';
import { mediaQuery } from 'src/theme/breakpoints';

const Wrapper = styled.div`
  display: grid;
  grid-column-gap: 60px;
  grid-template-columns: 1fr;

  ${mediaQuery('lg')} {
    grid-template-columns: 2fr 1fr;
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;

  & iframe {
    max-width: 100%;
  }
`;

const Aside = styled.div``;
interface ProductMainProps {
  description?: any;
  information?: any;
  gallery?: any;
  shortInfo?: any;
  activities?: any;
  days?: number;
  regularPrice: string;
  salePrice?: string;
  id: number;
  showAdvanceNotice?: boolean;
}

const ProductMain: FC<ProductMainProps> = ({
  description,
  information,
  shortInfo,
  gallery,
  activities,
  days,
  regularPrice,
  salePrice,
  id,
  showAdvanceNotice,
}) => (
  <Wrapper>
    <Main>
      <Section title="Apie" isCentered={false}>
        {description && (
          <div dangerouslySetInnerHTML={{ __html: description }} />
        )}

        {showAdvanceNotice && (
          <p>
            Užsakydami šią kelionę internetu dabar mokėsite 50% avansą, kuris
            garantuos jums vietą grupėje. Kitą dalį turėsite sumokėti likus
            mėnesiui iki kelionės pradžios.
          </p>
        )}

        {information && (
          <ProductTabs tabs={information} galleryImages={gallery} />
        )}
      </Section>
    </Main>
    <Aside>
      <ProductSummary
        shortInfo={shortInfo}
        activities={activities}
        days={days}
        regularPrice={regularPrice}
        salePrice={salePrice}
        id={id}
        showAdvanceNotice={showAdvanceNotice}
      />
    </Aside>
  </Wrapper>
);

export default ProductMain;
