import React from 'react';
import { graphql } from 'gatsby';

import { getMappedProductCategories } from 'src/components/Product/shared/helpers';
import { categoryFields } from 'src/components/MultistepForm/shared/constants';
import { ProductMain } from 'src/components/Product';
import SEO from 'src/components/Seo/Seo';
import Main from 'src/components/Main';
import Banner from 'src/components/Banner';
import { TestimonialsGrid } from 'src/components/Testimonials';
import { HeadingScope } from 'src/components/Heading';
import { domain } from 'src/utils/constants';

interface TripPageProps {
  data: any;
}

const TripPage: React.FC<TripPageProps> = ({
  data: { wpProduct: tripData },
}) => {
  const {
    databaseId,
    name,
    image,
    galleryImages,
    shortDescription,
    productFields: {
      days,
      fullPrice,
      fullPriceWithDiscount,
      people,
      information,
      recommendations,
      shortInfo,
    },
    seo,
    regularPrice,
    salePrice,
    productCategories,
  } = tripData;

  const mappedProductCategories = getMappedProductCategories(
    productCategories,
    {
      days,
      people,
    }
  );
  const activities = mappedProductCategories[categoryFields.ACTIVITIES];
  const countries = mappedProductCategories[categoryFields.COUNTRIES];
  const fullPriceToShow = fullPrice ? fullPrice : regularPrice;
  const salePriceToShow = fullPriceWithDiscount
    ? fullPriceWithDiscount
    : salePrice;

  return (
    <HeadingScope>
      <SEO
        title={seo.title}
        description={seo.metaDesc}
        image={
          seo.opengraphImage?.localFile
            ? seo.opengraphImage?.localFile
            : image.localFile
        }
      />
      <Banner
        image={image.localFile.childImageSharp.gatsbyImageData}
        title={name}
        button={{
          title: 'Užsakyti',
          url: `${domain}/checkout/?add-to-cart=${databaseId}`,
          isExternal: true,
        }}
        product={{
          days: days,
          countries: countries && countries[0].name,
          regularPrice: fullPriceToShow,
          salePrice: salePriceToShow,
          categories: productCategories,
        }}
        fullHeight
      />
      <Main>
        <ProductMain
          description={shortDescription}
          information={information}
          gallery={galleryImages.nodes}
          shortInfo={shortInfo}
          activities={activities}
          days={days}
          regularPrice={fullPriceToShow}
          salePrice={salePriceToShow}
          id={databaseId}
          showAdvanceNotice={fullPrice}
        />
        {recommendations && <TestimonialsGrid testimonials={recommendations} />}
      </Main>
    </HeadingScope>
  );
};

export const tripPageQuery = graphql`
  query tripProduct($id: String!) {
    wpProduct(id: { eq: $id }) {
      ... on WpSimpleProduct {
        ...YoastSeoProduct
        databaseId
        slug
        name
        shortDescription
        regularPrice
        salePrice
        productFields {
          days
          fullPrice
          fullPriceWithDiscount
          people
          information {
            content
            name
          }
          recommendations {
            person
            review
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 120)
                }
              }
            }
          }
          shortInfo {
            point
          }
        }
        productCategories {
          nodes {
            name
            slug
            wpParent {
              node {
                slug
              }
            }
          }
        }
        image {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        galleryImages {
          nodes {
            altText
            title
            description
            localFile {
              childImageSharp {
                thumb: gatsbyImageData(width: 270, height: 270)
                full: gatsbyImageData(width: 2048, quality: 95)
              }
            }
          }
        }
      }
    }
  }
`;

export default TripPage;
