import React, { FC } from 'react';
import styled from 'styled-components';

import Section from 'src/components/Section';
import { CompassIcon, SkiIcon, CalendarIcon, EuroIcon } from 'src/assets/icons';
import Button, { ButtonAppearance } from 'src/components/Button';
import { colors } from 'src/theme/colors';
import { domain } from 'src/utils/constants';

const Wrapper = styled.div`
  background-color: ${colors.blue100};
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  margin: 20px auto 0;
  max-width: 360px;
  padding: 30px 30px 40px;
`;

const List = styled.ul`
  list-style: none;
  margin-bottom: 40px;
  padding: 0;
`;

const ListItem = styled.li`
  align-items: center;
  display: flex;
  font-weight: 500;
  &:not(:first-child) {
    margin-top: 14px;
  }
`;

const IconWrapper = styled.div`
  height: 100%;
  margin-right: 20px;
  max-width: 30px;
  width: 100%;

  & svg {
    height: 30px;
    width: 30px;
  }

  & path,
  & svg {
    fill: ${colors.blue500};
  }
`;

const PriceWrapper = styled.div`
  align-items: center;
  display: flex;
`;

const RegularPrice = styled.span`
  margin-left: 6px;
  text-decoration: line-through;
`;

interface ProductSummaryProps {
  shortInfo: any;
  activities: any;
  days?: number;
  regularPrice: string;
  salePrice?: string;
  id: number;
  showAdvanceNotice?: boolean;
}

const ProductSummary: FC<ProductSummaryProps> = ({
  shortInfo,
  activities,
  days,
  regularPrice,
  salePrice,
  id,
  showAdvanceNotice,
}) => (
  <Wrapper>
    <Section title="Trumpai:">
      <List>
        {shortInfo &&
          shortInfo.map(node => (
            <ListItem key={node.point}>
              <IconWrapper>
                <CompassIcon />
              </IconWrapper>
              {node.point}
            </ListItem>
          ))}
        {activities && (
          <ListItem>
            <IconWrapper>
              <SkiIcon />
            </IconWrapper>
            Veiklos:{' '}
            {activities.map(
              (activity, index) => `${index > 0 ? ', ' : ' '}${activity.name}`
            )}{' '}
          </ListItem>
        )}
        {days && (
          <ListItem>
            <IconWrapper>
              <CalendarIcon />
            </IconWrapper>
            Trukmė: {days} d.
          </ListItem>
        )}
        <ListItem>
          <IconWrapper>
            <EuroIcon />
          </IconWrapper>
          {salePrice ? (
            <PriceWrapper>
              Kaina: {salePrice}
              <RegularPrice>{regularPrice}</RegularPrice>
            </PriceWrapper>
          ) : (
            <span>Kaina: {regularPrice}</span>
          )}
        </ListItem>
        {showAdvanceNotice && (
          <ListItem>
            <IconWrapper>
              <EuroIcon />
            </IconWrapper>
            <span>
              Užsakydami šią kelionę internetu dabar mokėsite 50% avansą, kuris
              garantuos jums vietą grupėje. Kitą dalį turėsite sumokėti likus
              mėnesiui iki kelionės pradžios.
            </span>
          </ListItem>
        )}
      </List>
      <Button
        isExternal
        url={`${domain}/checkout/?add-to-cart=${id}`}
        appearance={ButtonAppearance.ALFA}
      >
        Užsakyti
      </Button>
    </Section>
  </Wrapper>
);

export default ProductSummary;
